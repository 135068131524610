/**
 */
export enum FeatureFlag {
        /**
     * Flag used to test <see cref="FeatureFlagConfigurationService"/> in unit test.
     */
    UnitTest = -1,

    /**
     * Flag used to see the two last test <see cref="CompanyAutoDisablingFrequency"/> in company settings.
     */
    Test_AutoDisablingUser = 1,

    /**
     * Flag used to disable the M-Files bulk view download feature. (Start by DEBUG, since this feature flag is intended to stay for a while).
     */
    Debug_MFiles_BulkViewDownload_Disabled = 2,

    /**
     * Flag used to disable the advanced performance mode of the combo box.
     * Combo box will then stop using the virtual scroll, and will display all elements in the DOM.
     */
    Disable_Combo_Box_Advanced_Performance = 3,

    /**
     * Flag used to see test values (1 minute, 10 minutes) for session timeout in SSO settings.
     */
    Test_SsoSessionTimeout = 4,


}